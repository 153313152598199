import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from '../constants';
import { useRedux } from 'hooks';
import { UserRoleType } from '../types/user/role';

// Static Pages
const StaticPage = React.lazy(() => import('pages/staticPages/Details'));
const StaticPost = React.lazy(() => import('pages/staticPages/StaticPost'));

// Main Dashboard
const MainDashboard = React.lazy(() => import('pages/dashboard/Dashboard'));
const Shop = React.lazy(() => import('pages/dashboard/Shop'));
const Competitions = React.lazy(() => import('pages/dashboard/Competitions'));
const Ranking = React.lazy(() => import('pages/dashboard/Ranking'));

// Problems
const AllProblems = React.lazy(() => import('pages/problem/AllProblems'));
const AllSolutions = React.lazy(() => import('pages/problem/solution/AllSolutions'));
const ProblemDetailsPage = React.lazy(() => import('pages/problem/ProblemDetailsPage'));
const SolutionDetailsPage = React.lazy(() => import('pages/problem/solution/SolutionDetailsPage'));

// Theory - Modules
const AllModules = React.lazy(() => import('pages/module/AllModules'));
const ModuleDetailsPage = React.lazy(() => import('pages/module/ModuleDetailsPage'));

// Theory - Lectures
const LectureDetailsPage = React.lazy(() => import('pages/lecture/LectureDetailsPage'));
const QuizPage = React.lazy(() => import('pages/lecture/QuizPage'));

// Profile
const Support = React.lazy(() => import('pages/user/support/Support'));
const UserSettings = React.lazy(() => import('pages/user/settings/UserSettings'));
const UserEnergy = React.lazy(() => import('pages/user/settings/UserEnergy'));
const UserInvoices = React.lazy(() => import('pages/user/invoice/UserInvoices'));
const UserInvoiceStatus = React.lazy(() => import('pages/user/invoice/InvoiceStatus'));
const NewInvoice = React.lazy(() => import('pages/user/invoice/NewInvoice'));
const Notifications = React.lazy(() => import('pages/user/notification/Notification'));
const FriendshipAll = React.lazy(() => import('pages/user/friendship/FriendshipAll'));
const FriendshipIncoming = React.lazy(() => import('pages/user/friendship/FriendshipIncoming'));
const StaticSearch = React.lazy(() => import('pages/user/search/StaticSearch'));
const UserProfile = React.lazy(() => import('pages/user/profile/UserProfile'));

// Authentication pages
const Login = React.lazy(() => import('pages/auth/Login'));
const Logout = React.lazy(() => import('pages/auth/Logout'));
const Register = React.lazy(() => import('pages/auth/Register'));
const Confirm = React.lazy(() => import('pages/auth/Confirm'));
const Confirmed = React.lazy(() => import('pages/auth/Confirmed'));
const ForgetPassword = React.lazy(() => import('pages/auth/ForgetPassword'));
const ResetPassword = React.lazy(() => import('pages/auth/ResetPassword'));
const LockScreen = React.lazy(() => import('pages/auth/LockScreen'));

// Landing pages
const ContactUs = React.lazy(() => import('pages/landing/ContactUs'));
const Maintenance = React.lazy(() => import('pages/landing/Maintenance'));
const Landing = React.lazy(() => import('pages/landing/Landing'));

// Error pages
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const Page404 = React.lazy(() => import('pages/error/Page404'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

//Admin Pages - Comments
const AdminCommentsAll = React.lazy(() => import('pages/admin/comments/AdminCommentsAll'));
// Admin Pages - Dashboard
const AdminMainPage = React.lazy(() => import('pages/admin/main/AdminMainPage'));
const AdminLogs = React.lazy(() => import('pages/admin/main/AdminLogs'));
const AdminInfrastructure = React.lazy(() => import('pages/admin/main/AdminInfrastructure'));
const AdminQuicks = React.lazy(() => import('pages/admin/main/AdminQuicks'));
// Admin Pages - AI Chats
const AdminAiConfig = React.lazy(() => import('pages/admin/ai/AdminAiConfig'));
const AdminAiConversation = React.lazy(() => import('pages/admin/ai/AdminAiConversation'));
const AdminAiMessage = React.lazy(() => import('pages/admin/ai/AdminAiMessage'));
// Admin Pages - Compiler
const AdminCompilerTestSource = React.lazy(() => import('pages/admin/compiler/AdminCompilerTestSource'));
const AdminCompilerTestProblem = React.lazy(() => import('pages/admin/compiler/AdminCompilerTestProblem'));
const AdminCompilerLanguages = React.lazy(() => import('pages/admin/compiler/AdminCompilerLanguages'));
const AdminCompilerLogs = React.lazy(() => import('pages/admin/compiler/AdminCompilerLogs'));
// Admin Pages - Shop
const AdminShopProducts = React.lazy(() => import('pages/admin/shop/AdminShopProducts'));
const AdminShopInvoices = React.lazy(() => import('pages/admin/shop/AdminShopInvoices'));
// Admin Pages - Theory
const AdminTheoryModules = React.lazy(() => import('pages/admin/theory/AdminTheoryModules'));
const AdminTheoryModulesDetails = React.lazy(() => import('pages/admin/theory/AdminTheoryModuleDetails'));
const AdminTheoryChapters = React.lazy(() => import('pages/admin/theory/AdminTheoryChapters'));
const AdminTheoryChapterDetails = React.lazy(() => import('pages/admin/theory/AdminTheoryChapterDetails'));
const AdminTheoryLectures = React.lazy(() => import('pages/admin/theory/AdminTheoryLectures'));
const AdminTheoryLectureDetails = React.lazy(() => import('pages/admin/theory/AdminTheoryLectureDetails'));
// Admin Pages - Quiz
const AdminQuizQuestions = React.lazy(() => import('pages/admin/quiz/AdminQuizQuestions'));
const AdminQuizQuestionDetails = React.lazy(() => import('pages/admin/quiz/AdminQuizQuestionDetails'));
const AdminQuizAnswers = React.lazy(() => import('pages/admin/quiz/AdminQuizAnswers'));
const AdminQuizAnswerDetails = React.lazy(() => import('pages/admin/quiz/AdminQuizAnswerDetails'));
// Admin Pages - Problems
const AdminProblemsAll = React.lazy(() => import('pages/admin/problems/AdminProblemsAll'));
const AdminProblemDetails = React.lazy(() => import('pages/admin/problems/AdminProblemDetails'));
const AdminProblemsDrafts = React.lazy(() => import('pages/admin/problems/AdminProblemsDrafts'));
// Admin Pages - Support
const AdminSupportReports = React.lazy(() => import('pages/admin/support/report/AdminSupportReports'));
const AdminSupportTickets = React.lazy(() => import('pages/admin/support/ticket/AdminSupportTickets'));
// Admin Pages - CMS
const AdminCmsPages = React.lazy(() => import('pages/admin/cms/AdminCmsPages'));
const AdminCmsDependencies = React.lazy(() => import('pages/admin/cms/AdminCmsDependencies'));
const AdminCmsDependencyMap = React.lazy(() => import('pages/admin/cms/AdminDependencyMap/DependencyMap'));
const AdminCmsContacts = React.lazy(() => import('pages/admin/cms/AdminCmsContacts'));
const AdminCmsTags = React.lazy(() => import('pages/admin/cms/AdminCmsTags'));
const AdminCmsSchools = React.lazy(() => import('pages/admin/cms/AdminCmsSchools'));
const AdminCmsSeoData = React.lazy(() => import('pages/admin/cms/AdminSeoData'));
const AdminCmsSeoDataDetails = React.lazy(() => import('pages/admin/cms/AdminSeoDataDetails'));
// Admin Panel - Gallery
const AdminGalleryPhotos = React.lazy(() => import('pages/admin/gallery/AdminGalleryPhotos'));
const AdminGalleryAvatars = React.lazy(() => import('pages/admin/gallery/AdminGalleryAvatars'));
// Admin Pages - Logs
const AdminLogsEnergy = React.lazy(() => import('pages/admin/logs/AdminLogsEnergy'));
const AdminLogsSecurity = React.lazy(() => import('pages/admin/logs/AdminLogsSecurity'));
const AdminLogsSecurityDetails = React.lazy(() => import('pages/admin/logs/AdminLogsSecurityDetails'));
const AdminLogsLogins = React.lazy(() => import('pages/admin/logs/AdminLogsLogins'));
const AdminLogsLoginDetails = React.lazy(() => import('pages/admin/logs/AdminLogsLoginDetails'));
const AdminLogsTimespent = React.lazy(() => import('pages/admin/logs/AdminLogsTimespent'));

const loading = () => <div className=''></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {
            path: '/',
            element: <Root />,
        },
        {
            path: '/contact',
            element: <LoadComponent component={ContactUs} />,
        },
        {
            path: '/home',
            element: <LoadComponent component={Landing} />,
        },
        {
            path: '*',
            element: <Layout />,
            children: [
                {
                    path: '*',
                    element: <LoadComponent component={Page404} />,
                },
            ],
        },
        {
            path: '/admin',
            element: <PrivateRoute roles={[UserRoleType.ADMIN]} component={Layout} />,
            children: [
                {
                    path: 'dashboard',
                    children: [
                        {
                            path: 'home',
                            element: <LoadComponent component={AdminMainPage} />,
                        },
                        {
                            path: 'Logs',
                            element: <LoadComponent component={AdminLogs} />,
                        },
                        {
                            path: 'infrastructure',
                            element: <LoadComponent component={AdminInfrastructure} />,
                        },
                        {
                            path: 'quicks',
                            element: <LoadComponent component={AdminQuicks} />,
                        },
                    ],
                },
                {
                    path: 'ai',
                    children: [
                        {
                            path: 'config',
                            element: <LoadComponent component={AdminAiConfig} />,
                        },
                        {
                            path: 'conversations',
                            element: <LoadComponent component={AdminAiConversation} />,
                        },
                        {
                            path: 'messages',
                            element: <LoadComponent component={AdminAiMessage} />,
                        },
                    ],
                },
                {
                    path: 'compiler',
                    children: [
                        {
                            path: 'test-source',
                            element: <LoadComponent component={AdminCompilerTestSource} />,
                        },
                        {
                            path: 'test-problem',
                            element: <LoadComponent component={AdminCompilerTestProblem} />,
                        },
                        {
                            path: 'languages',
                            element: <LoadComponent component={AdminCompilerLanguages} />,
                        },
                        {
                            path: 'Logs',
                            element: <LoadComponent component={AdminCompilerLogs} />,
                        },
                    ],
                },
                {
                    path: 'shop',
                    children: [
                        {
                            path: 'products',
                            element: <LoadComponent component={AdminShopProducts} />,
                        },
                        {
                            path: 'invoices',
                            element: <LoadComponent component={AdminShopInvoices} />,
                        },
                    ],
                },
                {
                    path: 'theory',
                    children: [
                        {
                            path: 'modules',
                            element: <LoadComponent component={AdminTheoryModules} />,
                        },
                        {
                            path: 'modules/:moduleId',
                            element: <LoadComponent component={AdminTheoryModulesDetails} />,
                        },
                        {
                            path: 'chapters',
                            element: <LoadComponent component={AdminTheoryChapters} />,
                        },
                        {
                            path: 'chapters/:chapterId',
                            element: <LoadComponent component={AdminTheoryChapterDetails} />,
                        },
                        {
                            path: 'lectures',
                            element: <LoadComponent component={AdminTheoryLectures} />,
                        },
                        {
                            path: 'lectures/:lectureId',
                            element: <LoadComponent component={AdminTheoryLectureDetails} />,
                        },
                    ],
                },
                {
                    path: 'quiz',
                    children: [
                        {
                            path: 'questions',
                            element: <LoadComponent component={AdminQuizQuestions} />,
                        },
                        {
                            path: 'questions/:questionId',
                            element: <LoadComponent component={AdminQuizQuestionDetails} />,
                        },
                        {
                            path: 'answers',
                            element: <LoadComponent component={AdminQuizAnswers} />,
                        },
                        {
                            path: 'answers/:answerId',
                            element: <LoadComponent component={AdminQuizAnswerDetails} />,
                        },
                    ],
                },
                {
                    path: 'problems',
                    children: [
                        {
                            path: 'all',
                            element: <LoadComponent component={AdminProblemsAll} />,
                        },
                        {
                            path: ':problemId',
                            element: <LoadComponent component={AdminProblemDetails} />,
                        },
                        {
                            path: 'drafts',
                            element: <LoadComponent component={AdminProblemsDrafts} />,
                        },
                    ],
                },
                {
                    path: 'support',
                    children: [
                        {
                            path: 'reports',
                            element: <LoadComponent component={AdminSupportReports} />,
                        },
                        {
                            path: 'tickets',
                            element: <LoadComponent component={AdminSupportTickets} />,
                        },
                    ],
                },
                {
                    path: 'comments',
                    element: <LoadComponent component={AdminCommentsAll} />,
                },
                {
                    path: 'cms',
                    children: [
                        {
                            path: 'pages',
                            element: <LoadComponent component={AdminCmsPages} />,
                        },
                        {
                            path: 'dependencies',
                            children: [
                                {
                                    path: '',
                                    element: <LoadComponent component={AdminCmsDependencies} />,
                                },
                                {
                                    path: 'map',
                                    element: <LoadComponent component={AdminCmsDependencyMap} />,
                                },
                            ],
                        },
                        {
                            path: 'contacts',
                            element: <LoadComponent component={AdminCmsContacts} />,
                        },
                        {
                            path: 'tags',
                            element: <LoadComponent component={AdminCmsTags} />,
                        },
                        {
                            path: 'schools',
                            element: <LoadComponent component={AdminCmsSchools} />,
                        },
                        {
                            path: 'seo',
                            element: <LoadComponent component={AdminCmsSeoData} />,
                        },
                        {
                            path: 'seo/:seoId',
                            element: <LoadComponent component={AdminCmsSeoDataDetails} />,
                        },
                    ],
                },
                {
                    path: 'gallery',
                    children: [
                        {
                            path: 'images',
                            element: <LoadComponent component={AdminGalleryPhotos} />,
                        },
                        {
                            path: 'avatars',
                            element: <LoadComponent component={AdminGalleryAvatars} />,
                        },
                    ],
                },
                {
                    path: 'Logs',
                    children: [
                        {
                            path: 'energy',
                            element: <LoadComponent component={AdminLogsEnergy} />,
                        },
                        {
                            path: 'security',
                            element: <LoadComponent component={AdminLogsSecurity} />,
                        },
                        {
                            path: 'security/:logId',
                            element: <LoadComponent component={AdminLogsSecurityDetails} />,
                        },
                        {
                            path: 'logins',
                            element: <LoadComponent component={AdminLogsLogins} />,
                        },
                        {
                            path: 'logins/:logId',
                            element: <LoadComponent component={AdminLogsLoginDetails} />,
                        },
                        {
                            path: 'timespent',
                            element: <LoadComponent component={AdminLogsTimespent} />,
                        },
                    ],
                },
            ],
        },
        {
            // Private routes
            path: '/',
            element: <PrivateRoute roles={[UserRoleType.ADMIN, UserRoleType.USER]} component={Layout} />,
            children: [
                {
                    path: 'notifications',
                    element: <LoadComponent component={Notifications} />,
                },
                {
                    path: 'logs/energy',
                    element: <LoadComponent component={UserEnergy} />,
                },
                {
                    path: 'lectures',
                    children: [
                        {
                            path: '/lectures/:lectureSlug/questions',
                            element: <LoadComponent component={QuizPage} />,
                        },
                    ],
                },
                {
                    path: 'support',
                    element: <LoadComponent component={Support} />,
                },
                {
                    path: 'settings',
                    element: <LoadComponent component={UserSettings} />,
                },
                {
                    path: 'invoices',
                    element: <LoadComponent component={UserInvoices} />,
                },
                {
                    path: 'invoices/check/:uniqueId',
                    element: <LoadComponent component={UserInvoiceStatus} />,
                },
                {
                    path: 'friend-requests',
                    element: <LoadComponent component={FriendshipIncoming} />,
                },
                {
                    path: 'shop/:bundleSlug',
                    element: <LoadComponent component={NewInvoice} />,
                },
            ],
        },
        {
            path: '/',
            element: <Layout />,
            children: [
                {
                    path: 'posts/:slug',
                    element: <LoadComponent component={StaticPost} />,
                },
                {
                    path: 'dashboard',
                    element: <LoadComponent component={MainDashboard} />,
                },
                {
                    path: 'search',
                    element: <LoadComponent component={StaticSearch} />,
                },
                {
                    path: 'modules',
                    children: [
                        {
                            path: '/modules',
                            element: <LoadComponent component={AllModules} />,
                        },
                        {
                            path: '/modules/:moduleSlug',
                            element: <LoadComponent component={ModuleDetailsPage} />,
                        },
                    ],
                },
                {
                    path: 'lectures',
                    children: [
                        {
                            path: '/lectures/:lectureSlug',
                            element: <LoadComponent component={LectureDetailsPage} />,
                        },
                        {
                            path: '/lectures/:lectureSlug/questions',
                            element: <LoadComponent component={QuizPage} />,
                        },
                    ],
                },
                {
                    path: 'shop',
                    element: <LoadComponent component={Shop} />,
                },
                {
                    path: 'ranking',
                    element: <LoadComponent component={Ranking} />,
                },
                {
                    path: 'problems',
                    children: [
                        {
                            path: '/problems',
                            element: <LoadComponent component={AllProblems} />,
                        },
                        {
                            path: '/problems/:problemSlug',
                            element: <LoadComponent component={ProblemDetailsPage} />,
                        },
                    ],
                },
                {
                    path: 'evaluator',
                    children: [
                        {
                            path: '/evaluator',
                            element: <LoadComponent component={AllSolutions} />,
                        },
                        {
                            path: '/evaluator/:solutionId',
                            element: <LoadComponent component={SolutionDetailsPage} />,
                        },
                    ],
                },
                {
                    path: 'profile',
                    children: [
                        {
                            path: ':username/friends',
                            element: <LoadComponent component={FriendshipAll} />,
                        },
                        {
                            path: ':username',
                            element: <LoadComponent component={UserProfile} />,
                        },
                    ],
                },
            ],
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'pages/:pageSlug',
                    element: <LoadComponent component={StaticPage} />,
                },
                {
                    path: 'account',
                    children: [
                        {
                            path: 'login',
                            element: <LoadComponent component={Login} />,
                        },
                        {
                            path: 'register',
                            element: <LoadComponent component={Register} />,
                        },
                        {
                            path: 'confirm',
                            element: <LoadComponent component={Confirm} />,
                        },
                        {
                            path: 'confirmed',
                            element: <LoadComponent component={Confirmed} />,
                        },
                        {
                            path: 'forget-password',
                            element: <LoadComponent component={ForgetPassword} />,
                        },
                        {
                            path: 'reset-password/:token',
                            element: <LoadComponent component={ResetPassword} />,
                        },
                        {
                            path: 'lock-screen',
                            element: <LoadComponent component={LockScreen} />,
                        },
                        {
                            path: 'logout',
                            element: <LoadComponent component={Logout} />,
                        },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
            ],
        },
    ]);
};

export { AllRoutes };
