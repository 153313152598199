import { secInHour, secInMinute } from 'constants/platform';
import moment from 'moment-timezone'

export const secondsToHms = (seconds: number) => {
    seconds = Number(seconds);
    var h = Math.floor(seconds / secInHour);
    var m = Math.floor((seconds % secInHour) / secInMinute);
    var s = Math.floor((seconds % secInHour) % secInMinute);

    var hDisplay = h > 0 ? h + 'h' : '';
    var mDisplay = m > 0 ? m + 'm' : '';
    var sDisplay = s > 0 ? s + 's' : '';
    return hDisplay + mDisplay + sDisplay;
};

export const convertToMoment = (apiDate: number[], locale = 'ro'): moment.Moment => {
    const date = `${apiDate[0]}-${apiDate[1]}-${apiDate[2]} ${apiDate[3]}:${apiDate[4]}`
    if (process.env.REACT_APP_ENV_MODE === 'local') {
        return moment(date).locale(locale)
    }
    return moment.tz(date, 'Europe/Bucharest')
        .locale(locale)
};

export const convertToDateTime = (apiDate: number[]): string => {
    return convertToMoment(apiDate).format('YYYY-MM-DD HH:mm');
};

export const convertToDate = (apiDate: number[], local = 'ro'): string => {
    return convertToMoment(apiDate, local).format('YYYY-MM-DD');
};

export const convertToChatDate = (apiDate: number[], lang: string): string => {
    const messageDate = convertToMoment(apiDate).locale(lang);
    const currentDate = moment();
    let format = 'Do MMMM YYYY';
    if (currentDate.year() === messageDate.year()) {
        if (currentDate.month() === messageDate.month()) {
            if (currentDate.day() === messageDate.day()) {
                format = 'HH:mm';
            } else if (currentDate.day() - messageDate.day() <= 7) {
                format = 'dddd HH:mm';
            } else {
                format = 'Do MMMM';
            }
        } else {
            format = 'Do MMMM';
        }
    }
    return convertToMoment(apiDate).locale(lang).utcOffset(0, true).format(format);
};

export const convertToHumanDate = (apiDate: number[], lang: string): string => {
    return convertToMoment(apiDate).locale(lang).fromNow();
};
