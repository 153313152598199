import { Column } from 'react-table';
import { ProblemCompilerType } from './problem';

export const defaultCompilerFontSize = 14;
export const compilerFontSizes = [12, 14, 16, 18, 20, 22, 24];

export enum CompilerEndpoint {
    INTERNAL = 'internal',
    EXTERNAL = 'external',
}

export interface CompilerTheme {
    name: string;
    label: string;
    isDark?: boolean;
}

export const defaultCompilerTheme = 'github';

export const compilerThemes: CompilerTheme[] = [
    {
        name: 'chrome',
        label: 'Chrome',
    },
    {
        name: 'clouds',
        label: 'Cristi',
    },
    {
        name: 'github',
        label: 'Sonix',
    },
    {
        name: 'monokai',
        label: 'Monokai',
        isDark: true,
    },
    {
        name: 'twilight',
        label: 'Twilight',
        isDark: true,
    },
    {
        name: 'chaos',
        label: 'Chaos',
        isDark: true,
    },
];

export interface CompilerLanguage {
    internalId: number;
    label: string;
    version: string;
    code: string;
    defaultSourceCode: string;
}

export const compilerLanguages: CompilerLanguage[] = [
    {
        internalId: 1,
        label: 'C++',
        version: '19',
        code: 'c_cpp',
        defaultSourceCode: '#include <iostream>\n\nusing namespace std;\n\nint main()\n{\n    int a, b;\n    cin >> a >> b;\n    cout << a + b;\n    return 0;\n}',
    },
    {
        internalId: 2,
        label: 'Java',
        version: '11',
        code: 'java',
        defaultSourceCode:
            'import java.util.Scanner;\n\npublic class Main {\n    public static void main(String[] args) {\n        Scanner in = new Scanner(System.in); // Standard input\n        int a = in.nextInt();\n        int b = in.nextInt();\n        System.out.println(a + b);\n    }\n}',
    },
    {
        internalId: 3,
        label: 'Python',
        version: '3.8',
        code: 'python',
        defaultSourceCode: 'a = int(input())\nb = int(input())\nprint(a + b)',
    },
];

export const compilerTypes = [
    {
        id: 1,
        value: ProblemCompilerType.CONSOLE,
        label: 'Console',
    },
    {
        id: 2,
        value: ProblemCompilerType.FILE,
        label: 'File',
    },
    {
        id: 3,
        value: ProblemCompilerType.FUNCTION,
        label: 'Function',
    },
];

export interface AdminTestProblemRequest {
    sourceCode: string;
    languageId: number;
    problemId: string;
}

export interface AdminLogsRequest {
    type: string;
    id: string;
}

export interface AdminTestCaseProblem {
    testId: string;
    exitCode: number;
    exitSignal: number;
    wallTime: number;
    memory: number;
    message: string;
    status: number;
    stderr: string;
    stdout: string;
    time: number;
}

export interface AdminTestSourceRequest {
    sourceCode: string;
    languageId: number;
    compilerType: number;
    sourceValidator: string;
    fileName: string;
    input: string;
    cpuTimeLimit: number;
    cpuExtraTime: number;
    memoryLimit: number;
    stackLimit: number;
}

export interface AdminTestSourceResponse {
    compilationError: boolean;
    errorMessage: string;
    exitCode: number;
    exitSignal: number;
    memory: number;
    message: string;
    status: number;
    stderr: string;
    stdout: string;
    time: number;
    wallTime: number;
}

export interface InternalCompilerLanguage {
    id: number;
    intID: number;
    name: string;
    isDisabled: boolean;
    sourceFile: string;
    compileCmd: string;
    runCmd: string;
}

export interface UserTestSourceResponseTest {
    testId: string;
    exitCode: number;
    exitSignal: number;
    wallTime: number;
    memory: number;
    message: string;
    status: number;
    stderr: string;
    stdout: string;
    time: number;
}

export interface UserTestSourceResponse {
    compilationError: boolean;
    errorMessage: string;
    submissionId: string;
    tests: UserTestSourceResponseTest[];
}

export const allCompilerLanguageColumns: ReadonlyArray<Column> = [
    {
        Header: 'ID',
        accessor: 'language_id',
    },
    {
        Header: 'Name',
        accessor: 'name',
    },
    {
        Header: 'Enabled',
        accessor: 'is_disabled_badge',
    },
    {
        Header: 'Compile CMD',
        accessor: 'compileCmd',
    },
    {
        Header: 'Run CMD',
        accessor: 'runCmd',
    },
];

export type CompilerInternalStatusesType = {
    id: number;
    label: string;
};

export const compilerStatuses: CompilerInternalStatusesType[] = [
    {
        id: 0,
        label: 'Processing',
    },
    {
        id: 1,
        label: 'In Queue',
    },
    {
        id: 2,
        label: 'Analyzing',
    },
    {
        id: 3,
        label: 'Correct',
    },
    {
        id: 4,
        label: 'Wrong Answer',
    },
    {
        id: 5,
        label: 'Time Limit Exceeded',
    },
    {
        id: 6,
        label: 'Compile Error',
    },
    {
        id: 7,
        label: 'Runtime Error SIGSEGV',
    },
    {
        id: 8,
        label: 'Runtime Error SIGXFSZ',
    },
    {
        id: 9,
        label: 'Runtime Error SIGFPE',
    },
    {
        id: 10,
        label: 'Runtime Error SIGABRT',
    },
    {
        id: 11,
        label: 'Runtime Error NZEC',
    },
    {
        id: 12,
        label: 'Runtime Error Other',
    },
    {
        id: 13,
        label: 'Internal Error',
    },
    {
        id: 14,
        label: 'Exec Format Error',
    },
];

export const allCompilerTestColumns: ReadonlyArray<Column> = [
    {
        Header: 'Nr',
        accessor: 'test_id',
    },
    {
        Header: 'Timp',
        accessor: 'time_badge',
    },
    {
        Header: 'Memorie',
        accessor: 'memory_badge',
    },
    {
        Header: 'Evaluat',
        accessor: 'passed_badge',
    },
];

export interface UserEvaluatorResponseTest {
    testType: number;
    passed: boolean;
    points: number;
    time: number;
    maxTime: number;
    memory: number;
    maxMemory: number;
    statusId: number;
}

export interface UserEvaluatorResponse {
    id: string;
    errorMessage: string;
    status: number;
    tests: UserEvaluatorResponseTest[];
    problemPass: boolean;
    lecturePass: boolean;
    chapterPass: boolean;
    nextSlug?: string;
    reason?: string;
}
